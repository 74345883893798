body {
  margin: 0;
  min-height: 100%;
  background-color: #73D4F2;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}
h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'HelveticaRoundedLTStd', sans-serif;
  font-size: 48px;
  line-height: 1.3em;
  font-weight: 700;
  letter-spacing: -0.05em;
}
h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'HelveticaRoundedLTStd', sans-serif;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: -0.025em;
}
h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'HelveticaRoundedLTStd', sans-serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: -0.01em;
}
.App{
  background-color: #73D4F2;
  color: white;
  margin: 0;
  width: 100%;
}
.body {
  background-color: #73D4F2;
  width:50%;
  padding: 0;
  margin: 0 auto;
  text-align: center;
}
.top-body {
  background-color: #73D4F2;
  width: 100%;  
  padding: 0;
}
.header {
  background-color: #73D4F2;
  width: 100%;
  padding: 10px 10px 60px 10px;
  text-align: center;
  margin-bottom: 50px;
}
.large-heading {
  font-size: 9.5vw;
  line-height: 70px;
}
.small-heading {  
  margin-top: 40px;
  padding-right: 8px;
  float: right;  
  display: block;
}
.lemon-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 5%;
}
.desk-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 100%;
}
.text-block-2 {
  font-family: 'HelveticaRoundedLTStd';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
}
.error-text{
  font-family: 'HelveticaRoundedLTStd';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
}
.version {
  color:#bde8f5;
  width: 100%;
}
.middle-body {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.child-middle-body {  
  width: 50%;
  float: left;
  padding: 40px 0 20px 0;
}
.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'HelveticaRoundedLTStd';
  color: #fff;
  letter-spacing: -0.03em;
  text-decoration: none;
}
.w-nav-brand {
  text-decoration: none;
  color: #ffffff; 
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.right {
  background-color: #73D4F2;
  width: 44%;
  float:left;
}
.bold-text {
  font-weight: 400;
}
.body-display {
  color: #8a94a6;
  line-height: 1.55em;
  width: 70%;
  margin: 0 auto;
}
.body-display.large {
  margin-top: 20px;
  font-family: Inter, sans-serif;
  color: #e4e5e6;
  font-size:20px;
  line-height: 1.6em;
  font-weight: 200;
}
.right-intro{
  margin: 40px auto;
  padding: 10px;
  grid-auto-columns: 1fr;
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  border-style: solid;
  border-width: 1px;
  border-color: #e3e8f3;
  border-radius: 4px;
  width:455px;
}
.connect-button {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 60px;
  width: 300px;
  border: none;
  font-family: 'HelveticaRoundedLTStd';
  font-size: 28px;
  font-weight: bold;
  color: #73D4F2;
  text-align: center;
  vertical-align: middle;
  padding-top: 7px;
  margin-top: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.pass-code-input {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 50px;
  width: 300px;
  border: none;
  font-family: 'HelveticaRoundedLTStd';
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  vertical-align: middle;
  padding-top: 8px;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.enter-shop-button {
  background: #CDCDCD;
  border: 6px solid #DADADA;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 60px;
  width: 300px;
  font-family: 'HelveticaRoundedLTStd';
  font-size: 28px;
  color: #DADADA;
  text-align: center;
  vertical-align: middle;
  padding-top: 6px;
  -webkit-appearance: none;
}
.enter-shop-button-open {
  background: #73D4F2;
  border: 6px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 60px;
  width: 300px;
  font-family: 'HelveticaRoundedLTStd';
  font-size: 28px;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  padding-top: 6px;
  -webkit-appearance: none;
}
.svgLoader {
  animation: spin 0.5s linear infinite;
  margin: 40% 60% 60% 40%;
}
.divLoader {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed; /* Stay in place */
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0.6;
  margin: auto;
  background: #8a94a6;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


input[type=submit], input[type=Reset]{ 
  -webkit-appearance:none;
  -moz-appearance: none;
  appearance: none;
}
.error {
  height: 40px;
  width: 360px;
  border: none;
  font-family: 'HelveticaRoundedLTStd';
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;
}

@font-face {
  font-family: 'HelveticaRoundedLTStd';
  src: url('./fonts/HelveticaRoundedLTStd-Bd.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@media (max-width: 1390px) {
  
  .body { 
    width:70%;
  }
  .large-heading {
    font-size: 13.5vw;
    line-height: 70px;
  }
}



@media (max-width: 860px) {
  .middle-body {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .child-middle-body {
    width: 100%;   
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}



@media (max-width: 560px) {
  .lemon-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 10%;
  }
  .header {    
    padding: 0;
    text-align: none;
    margin-bottom: 50px;
  }  
  .large-heading {
    font-size: 46px;  
    line-height: 30px;  
  }
  .small-heading {  
    margin-top: 0;
    padding-right: 0;
    float: none;  
    display: block;
    font-size: 18px;
  }
  .middle-body {
    display: inline-block;
    margin: 0;
    width: 100%;
  }
  .child-middle-body {
    width: 100%;   
    padding: 10px 0;    
    margin-left: auto;
    margin-right: auto;
  }
  .connect-button {   
    width: 100%;  
    font-size: 24px;
    color: #5ab4cf;
  }
  .enter-shop-button {
    width: 100%;  
    font-size: 28px;
  }
  .enter-shop-button-open {
    width: 100%;  
    font-size: 28px;
  }
  .pass-code-input {
    margin-top:10px;
    padding-top: 10px;
    width:90%;    
  }
  .error-text{
    font-size: 13px;
    color: #ffffff;
  }
  .body-display {
    width: 100%;    
  }
  .body-display.large {   
    line-height: .5em;    
  }

  .error {
    width: 100%;
  }

  .svgLoader {
    animation: spin 0.5s linear infinite;
    margin: 40% 70% 40% 30%;
  }
}